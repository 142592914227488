import React from 'react'

//import the sections
import Header from '../components/Head.js'
import Layout from '../components/Layout.js'

import formStyle from '../components/form.module.scss'
import btn from '../components/main.module.scss'

export default function Home() {
  
  return (
    <Layout>
      <Header title="request"/>
      <div className={formStyle.container}>
        <h1>Contact</h1>
       {<form 
          className={formStyle.form}
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field">
            <input type="hidden" name="form-name" value="contact"/>
            <div className={formStyle.section}>
              <p className={formStyle.info}>Highlighted items are required</p>
              <div className={formStyle.row3}>
                <p>
                  <label> First Name <br/><input type="text" name="first-name" required="required"/> </label>
                </p>
                <p>
                  <label> Last Name <br/><input type="text" name="last-name" required="required"/> </label>
                </p>
                <p>
                  <label> Email <br/><input type="email" name="email-address"/> </label>
                </p>
              </div>
              <div className={formStyle.row2}>
                <p>
                  <label> Main Phone <br/><input type="tel" name="main-phone" required="required"/> </label>
                </p>
                <p>
                  <label> Secondary Phone <br/><input type="tel" name="secondary-phone"/> </label>
                </p>
              </div>
            </div>
            <div className={formStyle.section}>
              <div className={formStyle.row2}>
                <p>
                  <label> Service Address <br/><input type="text" name="service-address" required="required"/> </label>
                </p>
                <p>
                  <label> Service Address 2 <br/><input type="text" name="service-address-2" /> </label>
                </p>
              </div>
              <div className={formStyle.row3}>
                <p>
                  <label> City <br/><input type="text" name="service-city" required="required"/> </label>
                </p>
                <p>
                  <label> State <br/><input type="text" name="service-state" required="required"/> </label>
                </p>
                <p>
                  <label>Zip <br/><input type="text" name="zip" required="required"/></label>
                </p>
                <p className={formStyle.info}>We only service parts of Northeast Georgia and Macon County NC. Please call (706) 782-3760 if youre concerned you might not be in our service area.</p>
              </div>
            </div>
            <div className={`${formStyle.section} ${formStyle.blng}`}>
              <p className={formStyle.info}>Billing Address is needed if different from Service Address</p>
                <div className={formStyle.row2}>
                  <p>
                    <label> Billing Address <br/><input type="text" name="billing-address"/> </label>
                  </p>
                  <p>
                    <label> Billing Address 2 <br/><input type="text" name="billing-address-2"/> </label>
                  </p>
                </div>
                <div className={formStyle.row3}>
                  <p>
                    <label> City <br/><input type="text" name="billing-city"/> </label>
                  </p>
                  <p>
                    <label> State <br/><input type="text" name="billing-state"/> </label>
                  </p>
                  <p>
                    <label>Zip <br/><input type="text" name="billing-zip"/> </label>
                  </p>
                </div>
            </div>
            <div className={formStyle.section}>
              <div className={formStyle.row2}>
              <p>
                <label>Appliance Type <br/><input type="text" name="appliance-type" placeholder="Washer, Range, etc." required="required"/></label>
              </p>
              <p>
                <label>Applaince Brand <br/><input type="text" name="appliance-brand" placeholder="Maytag, Whirlpool, etc." required="required"/></label>
              </p>
              </div>
              <p className={formStyle.info} style={{'color': 'red'}}>We do not service GE, LG, Samsung or Hotpoint appliances</p>
            </div>
            <p className={formStyle.msg}>
                <label>Description of Issue <br/> <textarea name="message" cols="30" rows="10" required="required"></textarea></label>
              </p>
            <p>
              <button className={`${formStyle.btn} ${btn.btn}`} type="submit">Request Service</button>
            </p>
       </form>}
      </div>
    </Layout>
  )

}